/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import { mapState } from 'vuex'
import moment from 'moment'
import jspreadsheet from 'jspreadsheet-ce'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const onlyNumberRegex = /\B(?=(\d{3})+(?!\d))/g
const numberRegex = /^\d+$/

const column = (name, row) => jspreadsheet.getColumnNameFromId([String(name), row])
const configColumn = (instance, columnName) => instance.jexcel.getConfig().columns[columnName]
const columnValue = (instance, name, row) => instance.jexcel.getValueFromCoords(name, row)

export default {
  components: {
    ModalLoadingSubmit: () => import('./component/ModalLoadingSubmit.vue'),
    ModalSubmitConfirm: () => import('./component/ModalSubmitConfirm.vue'),
    ModalSuccess: () => import('./component/ModalSuccess.vue'),
    ModalError: () => import('./component/ModalErrors/ModalError.vue'),
    ModalReset: () => import('./component/ModalReset.vue'),
    ModalAlertComponent: () => import('./component/ModalAlert/ModalAlertComponent.vue'),
    ModalCodError: () => import('./component/ModalErrors/ModalCodError.vue'),
  },

  data() {
    return {
      loading: true,
      table: null,
      columnNumber: {
        order_date: 0,
        custom_label: 1,
        tracking_sales: 2,
        address_from: 3,
        customer_name: 4,
        customer_phone_number: 5,
        zip_code: 6,
        district_name: 7,
        customer_address: 8,
        product: 9,
        variant: 10,
        order_notes: 11,
        qty: 12,
        payment_method: 13,
        shipment: 14,
        grandtotal: 15,
      },
      loadingSaveDraft: false,

      districtItems: [],
      districtSource: [],
      shipmentItems: [],
      shipmentLoad: [],
      data: [],
      progressValue: 0,
      successCount: 0,

      pasteItem: {
        shipment: {
          item: [],
        },
        district: {
          item: [],
        },
      },

      alertState: {
        title: '',
        content: '',
        instance: null,
        column: null,
        type: '',
        actionState: '',
        titleAction: '',
        rowColumn: null,
      },
      stateLimitOrder: '',

      errorsState: {
        type: '',
        data: null,
      },

      disableSubmit: true,
      colPaste: null,
      rowPaste: null,
      grandtotalItem: [],
      phonePaste: null,
      phonePasteItem: [],
      qtyPaste: [],

      submitIsError: {
        value: false,
        data: null,
      },

      codError: [],
    }
  },

  computed: {
    ...mapState('ormas', [
      'source',
      'profile',
      'variantsProduct',
      'saldo',
      'shipmentIsActive',
      'dataSheets',
      'lastUpdated',
      'limitOrder',
      'shipmentIsFlat',
      'kompackSource',
      'isKompackUser',
    ]),
  },

  async beforeMount() {
    await this.initiateSheets()
  },

  mounted() {
    this.$refs['modal-opening-ormas'].show()
  },

  methods: {
    moveTopup() {
      this.$router.push({ name: 'dashboard' })
    },
    hideAlert() {
      this.setDefaultValue()
      this.$refs['modal-alert'].hide()
    },
    back() {
      this.$router.push({ name: 'method-order' })
    },
    async initiateSheets() {
      await this.$store.dispatch('ormas/fetchDropdownSheets').then(async data => {
        this.$store.commit('ormas/ADDRESS_SOURCE_UPDATE', data.addresses)
        this.$store.commit('ormas/CUSTOM_LABEL_SOURCE_UPDATE', data.custom_label)
        this.$store.commit('ormas/PAYMENT_SOURCE_UPDATE', data.payment_method)
        this.$store.commit('ormas/PRODUCT_SOURCE_UPDATE', data.products)
        this.$store.commit('ormas/VARIANT_SOURCE_UPDATE', data.variant)
        this.$store.commit('ormas/PRODUCT_WEIGHT_UPDATE', data.product_weight)
        this.$store.commit('ormas/TRACKING_SALES_SOURCE_UPDATE', data.tracking_sales)

        if (data.products.length === 0) {
          this.alertState.type = 'validation'
          this.alertState.titleAction = 'Sebelum lanjut membuat order, tambahkan produk yang akan kamu jual dahulu ya,'
          this.alertState.actionState = 'Tambah Produk'
          this.showAlert(this.alertState.type)
        }
        if (data.addresses.length === 0) {
          this.alertState.type = 'validation'
          this.alertState.titleAction = 'Tambahkan Alamat Pick Up untuk melanjutkan kegiatan tambah order.'
          this.alertState.actionState = 'Tambahkan Alamat Pickup'
          this.showAlert(this.alertState.type)
        }

        await this.$store.dispatch('ormas/limitOrderUser').then(() => {}, err => {
          this.toastErrors(err)
          this.$refs['modal-opening-ormas'].hide()
        })
        await this.$store.dispatch('ormas/shipmentCheck').then(() => {}, err => {
          this.toastErrors(err)
          this.$refs['modal-opening-ormas'].hide()
        })
        await this.$store.dispatch('ormas/saldoCheck').then(() => {}, err => {
          this.toastErrors(err)
          this.$refs['modal-opening-ormas'].hide()
        })
        await this.$store.dispatch('ormas/profileFetch').then(() => {}, err => {
          this.toastErrors(err)
          this.$refs['modal-opening-ormas'].hide()
        })
        await this.$store.dispatch('ormas/fetchKompackDropdown').then(response => {
          this.$store.commit('ormas/ADD_SOURCE_KOMPACK', response)
        })
        await this.$store.dispatch('ormas/fetchDraftOrder').then(() => {
          this.$refs['modal-opening-ormas'].hide()
          if (!this.shipmentIsActive) {
            this.alertState.type = 'validation'
            this.alertState.titleAction = 'Aktifkan Ekspedisi dulu ya,'
            this.alertState.actionState = 'Aktifkan Ekspedisi'
            this.showAlert(this.alertState.type)
          }

          this.data = this.dataSheets

          if (!this.limitOrder.data.data.is_lock) {
            this.$bvModal.hide('modal-validation-add-order')
          } else {
            this.stateLimitOrder = 'limit'
            this.$bvModal.show('modal-validation-add-order')
          }
          this.createSheets()
        }, err => {
          this.toastErrors(err)
          this.$refs['modal-opening-ormas'].hide()
        })
      }, err => {
        this.toastErrors(err)
        this.$refs['modal-opening-ormas'].hide()
      })
    },

    createSheets() {
      const options = {
        data: this.data,
        tableOverflow: true,
        minDimensions: [11, 200],
        tableHeight: '60vh',
        autoIncrement: false,
        defaultColWidth: 150,
        columns: this.initiateColumn(),
        // contextMenu: this.contextMenu,
        onload: this.loaded,
        onchange: this.changed,
        onbeforepaste: this.beforePasted,
        onpaste: this.pasted,
        allowInsertColumn: false,
      }

      this.table = jspreadsheet(document.getElementById('spreadsheet'), options)
      this.validationSubmit()
    },

    contextMenu(obj, x, y, e) {
      const items = []
      if (y !== null) {
        if (obj.options.allowInsertRow === true) {
          items.push({
            title: 'Sisipkan 1 baris baru di atasnya',
            onclick() {
              obj.insertRow(1, Number(y), 1)
            },
          })
          items.push({
            title: 'Sisipkan 1 baris baru di bawahnya',
            onclick() {
              obj.insertRow(1, Number(y))
            },
          })
        }
        if (obj.options.allowExport) {
          items.push({
            title: obj.options.text.saveAs,
            shortcut: 'Ctrl + S',
            onclick() {
              obj.download()
            },
          })
        }
      }
      return items
    },

    initiateColumn() {
      const COLUMN = [
        {
          type: 'calendar',
          title: 'Tanggal Order',
          options: {
            validRange: [
              moment().format('YYYY-MM-DD'),
              moment()
                .add(7, 'days')
                .format('YYYY-MM-DD'),
            ],
            format: 'YYYY-MM-DD',
            months: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
            weekdays: [
              'Minggu',
              'Senin',
              'Selasa',
              'Rabu',
              'Kamis',
              'Jumat',
              'Sabtu',
            ],
            weekdays_short: ['M', 'S', 'S', 'R', 'K', 'J', 'S'],
            textDone: 'SELESAI',
            textReset: 'HAPUS',
            textUpdate: 'OK',
          },
        },
        {
          type: 'dropdown',
          title: 'Kirim Sebagai',
          source: this.source.custom_label_source,
        },
        {
          type: 'dropdown',
          title: 'Terjual Oleh',
          source: this.source.tracking_sales_source,
        },
        {
          type: 'dropdown',
          title: 'Kirim Dari',
          source: this.source.address_source,
          filter: this.addressFilter,
        },
        { type: 'text', title: 'Nama Pembeli' },
        { type: 'numeric', title: 'Nomor HP' },
        { type: 'text', title: 'Kode Pos' },
        {
          type: 'dropdown',
          title: 'Kecamatan',
          source: this.districtSource,
          readOnly: true,
          filter: this.districtFilter,
        },
        { type: 'text', title: 'Alamat Detail', width: 250 },
        {
          type: 'dropdown',
          title: 'Produk',
          width: 200,
          source: this.source.product_source,
          filter: this.productFilter,
        },
        {
          type: 'dropdown',
          title: 'Variasi Spesifik',
          width: 300,
          source: this.source.variant_source,
          filter: this.variantFilter,
        },
        { type: 'text', title: 'Catatan Order' },
        { type: 'text', title: 'Kuantitas' },
        {
          type: 'dropdown',
          title: 'Metode pembayaran',
          width: 200,
          source: this.source.payment_source,
        },
        {
          type: 'dropdown',
          title: 'Ekspedisi',
          source: this.source.shipment_source,
          readOnly: true,
          filter: this.shipmentFilter,
        },
        {
          type: 'text',
          title: 'Nilai Pembayaran',
          mask: 'Rp #.##',
          decimal: ',',
        },
      ]

      const columns = []

      COLUMN.forEach((item, index) => {
        if (!this.profile.partner_is_custom_label && index === this.columnNumber.custom_label) {
          delete this.columnNumber.custom_label
        } else if (!this.profile.partner_is_tracking_sales && index === this.columnNumber.tracking_sales) {
          delete this.columnNumber.tracking_sales
        } else if (!this.profile.partner_is_order_notes && index === this.columnNumber.order_notes) {
          delete this.columnNumber.order_notes
        } else {
          columns.push(item)
        }
      })

      Object.entries(this.columnNumber).forEach((item, index) => this.columnNumber[item[0]] = index)

      return columns
    },

    loaded(instance) {
      this.data.forEach((item, index) => {
        const valCustomerName = columnValue(instance, this.columnNumber.customer_name, index)
        const valPhone = columnValue(instance, this.columnNumber.customer_phone_number, index)
        let valZipcode = columnValue(instance, this.columnNumber.zip_code, index)
        const valCustomerAddress = columnValue(instance, this.columnNumber.customer_address, index)
        const valDistrict = columnValue(instance, this.columnNumber.district_name, index)
        const valVariant = columnValue(instance, this.columnNumber.variant, index)
        const valShipment = columnValue(instance, this.columnNumber.shipment, index)
        let valAddressFrom = columnValue(instance, this.columnNumber.address_from, index)
        const valProduct = columnValue(instance, this.columnNumber.product, index)
        const valOrderNotes = columnValue(instance, this.columnNumber.order_notes, index)
        let valQty = columnValue(instance, this.columnNumber.qty, index)
        const valPayment = columnValue(instance, this.columnNumber.payment_method, index)
        const valGrandtotal = columnValue(instance, this.columnNumber.grandtotal, index)

        if (valCustomerName) {
          const columnName = column(this.columnNumber.customer_name, index)
          if (valCustomerName.length < 3 || valCustomerName.length > 30) {
            instance.jexcel.setComments(columnName, 'Nama pembeli minimal 3 karakter & maksimal 30 karakter')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }

        if (valPhone) {
          const columnName = column(this.columnNumber.customer_phone_number, index)
          let phone = valPhone.replace(/\D/g, '').replace(onlyNumberRegex, '')

          if (phone.charAt(0) === '6' && phone.charAt(1) === '2') {
            phone = `0${phone.substring(2)}`
          }

          if (phone.charAt(0) !== '0') {
            phone = `0${phone}`
          }

          if (phone.length < 11 || phone.length > 14) {
            instance.jexcel.setComments(columnName, 'Nomor HP minimal 10 angka & maksimal 14 angka')
          } else {
            instance.jexcel.setComments(columnName, '')
            instance.jexcel.setValue(columnName, phone)
          }
        }

        if (valCustomerAddress) {
          const columnName = column(this.columnNumber.customer_address, index)
          if (valCustomerAddress.length < 11 || valCustomerAddress.length > 120) {
            instance.jexcel.setComments(columnName, 'Alamat minimal 11 karakter & maksimal 120 karakter')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }

        if (valProduct) {
          const find = this.source.product_source.find(items => items === valProduct)
          if (find === undefined) {
            instance.jexcel.setValue(column(this.columnNumber.variant, index), '')
          }
        }

        if (valPayment) {
          const find = this.source.product_source.find(items => items === valProduct)
          if (find === undefined) {
            instance.jexcel.setValue(column(this.columnNumber.payment_method, index), '')
          }
        }

        if (valQty) {
          valQty = valQty.replace(/\D/g, '').replace(onlyNumberRegex, '')
          const columnName = column(this.columnNumber.qty, index)

          if (Number(valQty) < 1 || Number(valQty) > 1000) {
            instance.jexcel.setComments(columnName, 'Kuantitas harus berupa angka. Minimal 1 & maksimal 1000')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }

        if (valShipment) {
          this.shipmentLoad.push({ shipment: valShipment, row: index })
          const find = this.source.product_source.find(items => items === valProduct)
          if (find === undefined) {
            instance.jexcel.setValue(column(this.columnNumber.shipment, index), '')
          }
        }

        if (valGrandtotal) {
          const find = this.source.product_source.find(items => items === valProduct)
          if (find === undefined) {
            instance.jexcel.setValue(column(this.columnNumber.grandtotal, index), '')
          }
        }

        if (valOrderNotes) {
          const columnName = column(this.columnNumber.order_notes, index)
          if (valOrderNotes.length > 50) {
            instance.jexcel.setComments(columnName, 'Catatan order maksimal 50 karakter')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }

        if (valZipcode) {
          const columnName = column(this.columnNumber.district_name, index)
          instance.jexcel.setReadOnly(columnName, false)

          valZipcode = valZipcode.replace(/\D/g, '').replace(onlyNumberRegex, '')
          const colZipcode = column(this.columnNumber.zip_code, index)
          if (valZipcode.length < 5 || valZipcode.length > 5) {
            instance.jexcel.setComments(colZipcode, 'Kode pos harus 5 angka')
          } else {
            instance.jexcel.setComments(columnName, '')
            this.$store.dispatch('ormas/fetchDistrict', valZipcode).then(res => {
              const { data } = res.data

              const indexDistrict = this.districtItems.findIndex(items => Number(items.row) === Number(index))

              if (indexDistrict !== -1) {
                this.districtItems[indexDistrict].district = data.district
              } else {
                this.districtItems.push({
                  row: index,
                  district: data.district,
                })
              }

              data.district.forEach(districtItem => {
                this.districtSource.push(districtItem.district_name)
              })

              if (Number(data.total) > 0) {
                if (valDistrict !== '') instance.jexcel.setValue(columnName, valDistrict)
              }

              if (valAddressFrom && valZipcode && valDistrict && valProduct && valVariant && valQty && valPayment) {
                const weight = this.source.product_weight.find(items => items.product_name === valProduct)

                if (weight) {
                  const totalWeight = (valQty * weight.product_weight) / 1000
                  let params
                  if (valAddressFrom.includes('Kompack')) {
                    const findAddressFrom = this.kompackSource.find(items => valAddressFrom.includes(items.warehouse))
                    valAddressFrom = findAddressFrom.warehouse
                    params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}&is_kompack=1`
                  } else {
                    params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}`
                  }
                  this.$store.dispatch('ormas/calculate', params).then(response => {
                    const findIndex = this.shipmentItems.findIndex(items => Number(items.row) === Number(index))

                    if (findIndex === -1) {
                      this.shipmentItems.push({
                        row: index,
                        shipment: response,
                      })
                    } else {
                      this.shipmentItems[findIndex].shipment = response
                    }

                    const colShipment = column(this.columnNumber.shipment, index)
                    instance.jexcel.setReadOnly(colShipment, false)
                    configColumn(instance, this.columnNumber.shipment).source = response
                    const find = this.shipmentLoad.find(items => Number(items.row) === Number(index))
                    const findIndexLoad = this.shipmentLoad.findIndex(items => Number(items.row) === Number(index))

                    if (find) {
                      instance.jexcel.setValue(colShipment, find.shipment)
                      this.shipmentLoad.splice(findIndexLoad, 1)
                    }
                  })
                }
              } else {
                const colShipment = column(this.columnNumber.shipment, index)
                instance.jexcel.setValue(colShipment, '')
                instance.jexcel.setReadOnly(colShipment, true)
              }
            }, err => {
              this.toastErrors(err)
            })
          }
        }
      })
    },

    changed(instance, cell, col, row, value) {
      this.alertState.instance = instance
      this.alertState.rowColumn = row

      if (col === String(this.columnNumber.order_date)) {
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.custom_label)) {
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.tracking_sales)) {
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.customer_name)) {
        if (value.length < 3 || value.length > 30) {
          this.alertState.type = 'customer_name'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setComments(column(this.columnNumber.customer_name, row), '')
        }
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.customer_phone_number)) {
        let phone = value.replace(/\D/g, '').replace(onlyNumberRegex, '')
        if (phone.charAt(0) === '6' && phone.charAt(1) === '2') {
          phone = `0${phone.substring(2)}`
        }
        if (phone.charAt(0) !== '0') {
          phone = `0${phone}`
        }
        if (phone.length < 10 || phone.length > 14) {
          this.alertState.type = 'nomor_hp'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setValue(column(this.columnNumber.customer_phone_number, row), phone)
          instance.jexcel.setComments(column(this.columnNumber.customer_phone_number, row), '')
        }
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.address_from)) {
        this.pasteItem.shipment.item = []
        const colProduct = column(this.columnNumber.product, row)
        const colVariant = column(this.columnNumber.variant, row)

        if (!this.isKompackUser) {
          instance.jexcel.setValue(colProduct, '')
          instance.jexcel.setValue(colVariant, '')
        }

        this.checkCalculate(instance, row)
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.zip_code)) {
        const columnName = column(this.columnNumber.district_name, row)
        const colShipment = column(this.columnNumber.shipment, row)

        if (!numberRegex.test(value) || value < 10110 || value > 99974) {
          this.alertState.type = 'zip_code'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setComments(column(this.columnNumber.zip_code, row), '')
        }

        switch (value) {
          case (''):
            instance.jexcel.setValue(columnName, '')
            instance.jexcel.setReadOnly(columnName, true)
            instance.jexcel.setValue(colShipment, '')
            instance.jexcel.setReadOnly(colShipment, true)
            break
          default:
            instance.jexcel.setValue(columnName, '')
            this.pasteItem.shipment.item = []
            this.checkDistrict(instance, row, value)
            break
        }

        this.validationSubmit()
      }

      if (col === String(this.columnNumber.customer_address)) {
        if (value.length < 11 || value.length > 120) {
          this.alertState.type = 'detail_address'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setComments(column(this.columnNumber.customer_address, row), '')
        }
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.district_name)) {
        this.pasteItem.shipment.item = []
        this.checkCalculate(instance, row)
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.product)) {
        const columnName = column(this.columnNumber.variant, row)
        const colPayment = column(this.columnNumber.payment_method, row)
        const colShipment = column(this.columnNumber.shipment, row)
        const colGrandtotal = column(this.columnNumber.grandtotal, row)

        switch (value) {
          case (''):
            instance.jexcel.setValue(columnName, '')
            instance.jexcel.setValue(colPayment, '')
            instance.jexcel.setValue(colShipment, '')
            instance.jexcel.setValue(colGrandtotal, '')
            this.checkCalculate(instance, row)
            break
          default:
            instance.jexcel.setValue(colPayment, '')
            instance.jexcel.setValue(colShipment, '')
            instance.jexcel.setValue(colGrandtotal, '')
            this.pasteItem.shipment.item = []
            this.checkCalculate(instance, row)
            break
        }

        const find = this.variantsProduct.find(item => item.product_name === value)

        if (value) {
          if (find?.variant.length > 1) {
            instance.jexcel.setValue(columnName, '')
          } else {
            instance.jexcel.setValue(columnName, '-')
          }
        }

        this.validationSubmit()
      }

      if (col === String(this.columnNumber.variant)) {
        this.pasteItem.shipment.item = []
        this.checkCalculate(instance, row)
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.order_notes)) {
        if (value.length > 50) {
          this.alertState.type = 'order_notes'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setComments(column(this.columnNumber.order_notes, row), '')
        }
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.qty)) {
        if (!numberRegex.test(value) || Number(value) < 1 || Number(value) > 1000) {
          this.alertState.type = 'qty'
          this.showAlert(this.alertState.type)
        } else {
          instance.jexcel.setComments(column(this.columnNumber.qty, row), '')
        }
        this.pasteItem.shipment.item = []
        this.checkCalculate(instance, row)
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.payment_method)) {
        if (value === 'BANK TRANSFER' && this.saldo < 0) {
          this.alertState.type = 'saldo'
          this.showAlert(this.alertState.type)
        }
        this.pasteItem.shipment.item = []
        this.checkCalculate(instance, row)
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.shipment)) {
        this.validationSubmit()
      }

      if (col === String(this.columnNumber.grandtotal)) {
        const columnName = column(this.columnNumber.grandtotal, row)
        instance.jexcel.setValue(columnName, String(value).replaceAll('.', ''))
        this.validationSubmit()
      }
    },
    beforePasted(instance, value, col, row) {
      this.pasteItem.shipment.item = []
      this.pasteItem.district.item = []

      if (col === String(this.columnNumber.customer_name)) {
        const columnName = column(this.columnNumber.customer_name, row)
        if (value.length < 3 || value.length > 30) {
          instance.jexcel.setComments(columnName, 'Nama pembeli minimal 3 karakter & maksimal 30 karakter')
        } else {
          instance.jexcel.setComments(columnName, '')
        }
      }
      if (col === String(this.columnNumber.customer_phone_number)) {
        const columnName = column(this.columnNumber.customer_phone_number, row)
        let phone = value.replace(/\D/g, '').replace(onlyNumberRegex, '')

        if (!phone) return false

        if (phone.charAt(0) === '6' && phone.charAt(1) === '2') {
          phone = `0${phone.substring(2)}`
        }

        if (phone.charAt(0) !== '0') {
          phone = `0${phone}`
        }

        if (phone.length < 11 || phone.length > 14) {
          instance.jexcel.setComments(columnName, 'Nomor HP minimal 10 angka & maksimal 14 angka')
        } else {
          instance.jexcel.setComments(columnName, '')
          instance.jexcel.setValue(columnName, phone)
          this.phonePaste = phone
          this.rowPaste = row
        }
      }
      if (col === String(this.columnNumber.zip_code)) {
        const rowValue = value.split('\n')
        const cellItem = []
        const start = Number(instance.jexcel.selectedCell[1])
        const end = Number(instance.jexcel.selectedCell[1]) + rowValue.length

        for (let i = start; i < end; i += 1) {
          cellItem.push(i)
        }

        let split = null

        if (rowValue[rowValue.length - 1] === '') rowValue.pop()

        for (let i = 0; i < rowValue.length; i += 1) {
          split = rowValue[i].split('\t')
          value = split[0].replace(/\D/g, '').replace(onlyNumberRegex, '')
          if (numberRegex.test(value)) {
            if (value !== '') {
              const columnName = column(this.columnNumber.zip_code, String(cellItem[i]))
              if (value.length < 5 || value.length > 5) {
                instance.jexcel.setComments(columnName, 'Kode pos harus 5 angka')
              } else {
                instance.jexcel.setComments(columnName, '')
                this.checkDistrict(instance, String(cellItem[i]), value)
              }
            }
          } else {
            const columnName = column(this.columnNumber.zip_code, String(cellItem[i]))
            instance.jexcel.setComments(columnName, 'Kode pos harus 5 angka')
            instance.jexcel.setValue(column(this.columnNumber.district_name, String(cellItem[i])), '')
            instance.jexcel.setReadOnly(column(this.columnNumber.district_name, String(cellItem[i])), true)
          }
        }
      }
      if (col === String(this.columnNumber.district_name)) {
        const findItem = this.districtItems.find(item => Number(item.row) === Number(row))
        const items = []
        findItem.district.forEach(item => items.push(item.district_name))
        if (!items.includes(value)) return false
      }
      if (col === String(this.columnNumber.customer_address)) {
        const columnName = column(this.columnNumber.customer_address, row)
        if (value) {
          if (value.length < 11 || value.length > 120) {
            instance.jexcel.setComments(columnName, 'Alamat minimal 11 karakter & maksimal 120 karakter')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }
      }
      if (col === String(this.columnNumber.product)) {
        const colVariant = column(this.columnNumber.variant, row)
        const colAddress = column(this.columnNumber.address_from, row)
        const findWarehouse = this.kompackSource.filter(item => item.product.includes(value))

        if (findWarehouse.length === 1) {
          instance.jexcel.setValue(colAddress, `[Kompack] ${findWarehouse[0].warehouse}`)
        }
        instance.jexcel.setValue(colVariant, '')
      }
      if (col === String(this.columnNumber.variant)) {
        const colProduct = column(this.columnNumber.product, row)
        const findVariant = this.variantsProduct.find(item => item.variant.includes(value))

        if (findVariant) {
          instance.jexcel.setValue(colProduct, findVariant.product_name)
        } else {
          return false
        }
      }
      if (col === String(this.columnNumber.order_notes)) {
        const columnName = column(this.columnNumber.order_notes, row)
        if (value) {
          if (value.length > 50) {
            instance.jexcel.setComments(columnName, 'Catatan order maksimal 50 karakter')
          } else {
            instance.jexcel.setComments(columnName, '')
          }
        }
      }
      if (col === String(this.columnNumber.qty)) {
        this.qtyPaste = []
        const columnName = column(this.columnNumber.qty, row)
        this.colPaste = col
        this.rowPaste = row

        const rowValue = value.split('\n')
        const cellItem = []
        const start = Number(instance.jexcel.selectedCell[1])
        const end = Number(instance.jexcel.selectedCell[1]) + rowValue.length

        for (let i = start; i < end; i += 1) {
          cellItem.push(i)
        }

        let split = null

        for (let i = 0; i < rowValue.length; i += 1) {
          split = rowValue[i].split('\t')
          for (let x = 0; x < split.length; x += 1) {
            split[x] = split[x].replace(/\D/g, '').replace(onlyNumberRegex, '')
            this.qtyPaste.push({ value: split[x], row: cellItem[i] })
            if (split[x] !== '') {
              if (Number(split[x]) < 1 || Number(split[x]) > 1000) {
                instance.jexcel.setComments(columnName, 'Kuantitas harus berupa angka. Minimal 1 & maksimal 1000')
              } else {
                instance.jexcel.setComments(columnName, '')
              }
            }
          }
        }
      }
      if (col === String(this.columnNumber.shipment)) {
        const findItem = this.shipmentItems.find(item => Number(item.row) === Number(row))
        if (!findItem.shipment.includes(value)) return false
      }
      if (col === String(this.columnNumber.grandtotal)) {
        this.grandtotalItem = []
        this.colPaste = col
        this.rowPaste = row

        const rowValue = value.split('\n')
        const cellItem = []
        const start = Number(instance.jexcel.selectedCell[1])
        const end = Number(instance.jexcel.selectedCell[1]) + rowValue.length

        for (let i = start; i < end; i += 1) {
          cellItem.push(i)
        }

        let split = null

        for (let i = 0; i < rowValue.length; i += 1) {
          split = rowValue[i].split('\t')
          for (let x = 0; x < split.length; x += 1) {
            this.grandtotalItem.push(split[x])
          }
        }
      }

      if (Number(col) === 0) {
        let rowValue = value.split('\n')
        let split = null

        if (rowValue.length > this.table.getJson().length) rowValue = rowValue.slice(0, this.table.getJson().length)

        for (let i = 0; i < rowValue.length; i += 1) {
          split = rowValue[i].split('\t')
          split = split.slice(0, Object.keys(this.columnNumber).length)

          for (let x = 0; x < split.length; x += 1) {
            if (x === this.columnNumber.customer_name) {
              if (split[x]) {
                const columnName = column(this.columnNumber.customer_name, String(Number(row) + i))
                if (split[x].length < 3 || split[x].length > 30) {
                  instance.jexcel.setComments(columnName, 'Nama pembeli minimal 3 karakter & maksimal 30 karakter')
                } else {
                  instance.jexcel.setComments(columnName, '')
                }
              }
            }

            if (x === this.columnNumber.customer_phone_number) {
              if (split[x]) {
                const columnName = column(this.columnNumber.customer_phone_number, String(Number(row) + i))
                let phone = split[x].replace(/\D/g, '').replace(onlyNumberRegex, '')

                if (phone.charAt(0) === '6' && phone.charAt(1) === '2') {
                  phone = `0${phone.substring(2)}`
                }

                if (phone.charAt(0) !== '0') {
                  phone = `0${phone}`
                }

                if (phone.length < 11 || phone.length > 14) {
                  instance.jexcel.setComments(columnName, 'Nomor HP minimal 10 angka & maksimal 14 angka')
                } else {
                  instance.jexcel.setComments(columnName, '')
                  instance.jexcel.setValue(columnName, phone)
                  this.phonePasteItem.push({ row: String(Number(row) + i), value: phone })
                }
              }
            }

            if (x === this.columnNumber.shipment) {
              if (split[x]) {
                this.pasteItem.shipment.item.push({ value: split[x], row: Number(row) + i })
              } else {
                this.pasteItem.shipment.item.push({ value: '', row: Number(row) + i })
              }
            }

            if (x === this.columnNumber.zip_code) {
              if (split[x]) {
                split[x] = split[x].replace(/\D/g, '').replace(onlyNumberRegex, '')
                const columnName = column(this.columnNumber.zip_code, String(Number(row) + i))

                if (split[x].length < 5 || split[x].length > 5) {
                  instance.jexcel.setComments(columnName, 'Kode pos harus 5 angka')
                } else {
                  instance.jexcel.setComments(columnName, '')
                }

                this.pasteItem.district.item.push({ value: split[this.columnNumber.district_name], zip_code: split[x], row: Number(row) + i })
              } else {
                this.pasteItem.district.item.push({ value: '', zip_code: '', row: Number(row) + i })
              }
            }

            if (col === this.columnNumber.customer_address) {
              const columnName = column(this.columnNumber.customer_address, String(Number(row) + i))
              if (split[x]) {
                if (split[x].length < 11 || split[x].length > 120) {
                  instance.jexcel.setComments(columnName, 'Alamat minimal 11 karakter & maksimal 120 karakter')
                } else {
                  instance.jexcel.setComments(columnName, '')
                }
              }
            }

            if (col === this.columnNumber.order_notes) {
              const columnName = column(this.columnNumber.order_notes, String(Number(row) + i))
              if (split[x]) {
                if (split[x].length > 50) {
                  instance.jexcel.setComments(columnName, 'Catatan order maksimal 50 karakter')
                } else {
                  instance.jexcel.setComments(columnName, '')
                }
              }
            }

            if (x === this.columnNumber.qty) {
              if (split[x]) {
                split[x] = split[x].replace(/\D/g, '').replace(onlyNumberRegex, '')
                const columnName = column(this.columnNumber.qty, String(Number(row) + i))
                if (Number(split[x]) < 1 || Number(split[x]) > 1000) {
                  instance.jexcel.setComments(columnName, 'Kuantitas harus berupa angka. Minimal 1 & maksimal 1000')
                } else {
                  this.qtyPaste.push({ value: split[x], row })
                  instance.jexcel.setComments(columnName, '')
                }
              }
            }

            if (x === this.columnNumber.grandtotal) {
              if (split[x]) {
                this.grandtotalItem.push(split[x])
              }
            }
          }
        }
      }
    },
    pasted(instance) {
      if (this.phonePaste) {
        const columnName = column(this.columnNumber.customer_phone_number, this.rowPaste)
        instance.jexcel.setComments(columnName, '')
        instance.jexcel.setValue(columnName, this.phonePaste)
      }

      this.pasteItem.district.item.forEach(async (item, index) => {
        if (item.zip_code.length === 5) {
          const columnName = column(this.columnNumber.district_name, item.row)
          instance.jexcel.setReadOnly(columnName, false)

          await this.$store.dispatch('ormas/fetchDistrict', item.zip_code).then(async res => {
            const { data } = res.data

            const findIndex = this.districtItems.findIndex(items => Number(items.row) === Number(item.row))

            if (findIndex !== -1) {
              this.districtItems[findIndex].district = data.district
            } else {
              this.districtItems.push({
                row: item.row,
                district: data.district,
              })
            }

            data.district.forEach(districtItem => {
              this.districtSource.push(districtItem.district_name)
            })

            if (Number(data.total) > 0) {
              if (item.value) instance.jexcel.setValue(columnName, item.value)
              this.checkCalculate(instance, item.row, index)
            }
          }, err => {
            this.toastErrors(err)
          })
        }
      })

      if (this.colPaste === String(this.columnNumber.grandtotal) || this.colPaste === String(0) || this.colPaste === null) {
        const cellItem = []
        const start = Number(instance.jexcel.selectedCell[1])
        const end = Number(instance.jexcel.selectedCell[1]) + this.grandtotalItem.length

        for (let i = start; i < end; i += 1) {
          cellItem.push(i)
        }

        for (let i = 0; i < this.grandtotalItem.length; i += 1) {
          const columnName = column(this.columnNumber.grandtotal, cellItem[i])
          const removeComma = String(this.grandtotalItem[i]).replaceAll(',', '')
          const removeDot = String(removeComma).replaceAll('.', '')
          instance.jexcel.setValue(columnName, removeDot.replace(/\D/g, '').replace(onlyNumberRegex, ''))
        }
      }

      if (this.phonePasteItem.length > 0) {
        for (let x = 0; x < this.phonePasteItem.length; x += 1) {
          const columnName = column(this.columnNumber.customer_phone_number, this.phonePasteItem[x].row)
          instance.jexcel.setValue(columnName, this.phonePasteItem[x].value)
        }
      }

      if (this.colPaste === String(this.columnNumber.qty)) {
        for (let x = 0; x < this.qtyPaste.length; x += 1) {
          if (this.qtyPaste[x].value) {
            instance.jexcel.setValue(column(this.columnNumber.qty, this.qtyPaste[x].row), this.qtyPaste[x].value)
          } else {
            instance.jexcel.setValue(column(this.columnNumber.qty, this.qtyPaste[x].row), '')
          }
        }
      }

      this.phonePaste = null
      this.phonePasteItem = []
      this.grandtotalItem = []
      this.colPaste = null
      this.rowPaste = null
      this.qtyPaste = []
    },

    districtFilter(instance, cell, col, row, source) {
      const findItem = this.districtItems.find(item => Number(item.row) === Number(row))

      const dataSource = []

      findItem.district.forEach(item => {
        dataSource.push(item.district_name)
      })

      const result = [...new Set(dataSource)]
      return result
    },

    // Kompack
    addressFilter(instance, cell, col, row, source) {
      const valProduct = instance.jexcel.getValueFromCoords(this.columnNumber.product, row)
      if (valProduct) {
        const findWarehouse = this.kompackSource.filter(item => item.product.includes(valProduct))
        const addressSource = []
        const warehousePersonal = source.filter(item => !item.includes('[Kompack]'))
        if (findWarehouse.length !== 0) {
          findWarehouse.forEach(item => addressSource.push(`[Kompack] ${item.warehouse}`))
          return addressSource.concat(warehousePersonal)
        } else {
          return warehousePersonal
        }
      }
      return source
    },
    productFilter(instance, cell, col, row, source) {
      const warehouse = instance.jexcel.getValueFromCoords(this.columnNumber.address_from, row)
      const normalizedWarehouse = warehouse.trim().replace(/\[Kompack\] /, '')

      const matchingWarehouse = this.kompackSource.find(item => item.warehouse === normalizedWarehouse)

      let filteredProducts

      if (matchingWarehouse) {
        filteredProducts = new Set(matchingWarehouse.product)
      } else {
        filteredProducts = new Set(source)
      }

      return Array.from(filteredProducts)
    },

    variantFilter(instance, cell, col, row, source) {
      const value = instance.jexcel.getValueFromCoords(col - 1, row)
      const result = this.variantsProduct.find(item => item.product_name === value)

      if (result) {
        return result.variant
      }

      return source
    },
    shipmentFilter(instance, cell, col, row, source) {
      const filter = this.shipmentItems.find(item => Number(item.row) === Number(row))
      return filter.shipment
    },

    undo() {
      this.table.undo()
    },
    redo() {
      this.table.redo()
    },
    addRows() {
      this.table.insertRow(200)
    },
    closeModalLimit() {
      if (this.modalLimitOrder === 'progress') {
        this.$router.go('/add-order-massal')
      } else if (this.modalLimitOrder === 'limit') {
        this.$router.push({ name: 'dashboard-komship' })
      } else {
        this.$bvModal.hide('modal-validation-add-order')
        window.location.reload()
      }
    },
    showAlert(state) {
      switch (state) {
        case ('customer_name'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Nama pembeli minimal 3 karakter & maksimal 30 karakter'
          break
        case ('nomor_hp'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Nomor HP minimal 10 angka & maksimal 14 angka'
          break
        case ('zip_code'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Kode pos harus 5 angka'
          break
        case ('detail_address'):
          this.alertState.title = 'Upss., belum lengkap nih..'
          this.alertState.content = 'Alamat minimal 11 karakter & maksimal 120 karakter'
          break
        case ('qty'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Kuantitas harus berupa angka. Minimal 1 & maksimal 1000'
          break
        case ('grandtotal'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Harga Tagihan Pembeli harus lebih dari Ongkir Pengiriman yaa..'
          break
        case ('order_notes'):
          this.alertState.title = 'Upss., belum tepat nih..'
          this.alertState.content = 'Catatan order maksimal 50 karakter'
          break
        default:
          break
      }
      this.$refs['modal-alert'].show()
    },
    toastErrors(state) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: state,
          variant: 'primary',
        },
      }, 2000)
    },
    setDefaultValue() {
      switch (this.alertState.type) {
        case ('customer_name'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.customer_name, this.alertState.rowColumn), '')
          break
        case ('nomor_hp'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.customer_phone_number, this.alertState.rowColumn), '')
          break
        case ('zip_code'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.zip_code, this.alertState.rowColumn), '')
          break
        case ('detail_address'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.customer_address, this.alertState.rowColumn), '')
          break
        case ('qty'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.qty, this.alertState.rowColumn), '')
          this.alertState.instance.jexcel.setComments(column(this.columnNumber.qty, this.alertState.rowColumn), '')
          break
        case ('saldo'):
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.payment_method, this.alertState.rowColumn), '')
          this.alertState.instance.jexcel.setValue(column(this.columnNumber.shipment, this.alertState.rowColumn), '')
          this.alertState.instance.jexcel.setReadOnly(column(this.columnNumber.shipment, this.alertState.rowColumn), true)
          this.validationSubmit()
          this.$refs['loading-component'].close()
          break
        default:
          break
      }
    },
    checkDistrict(instance, row, zipcode) {
      const colShipment = column(this.columnNumber.shipment, row)
      instance.jexcel.setValue(colShipment, '')
      const columnName = column(this.columnNumber.district_name, row)
      instance.jexcel.setValue(columnName, '')
      instance.jexcel.setReadOnly(columnName, false)

      this.$store.dispatch('ormas/fetchDistrict', zipcode).then(res => {
        const { data } = res.data
        const reduceDistrict = data.district.length > 0 ? data.district.reduce(item => item) : []
        const findIndex = this.districtItems.findIndex(item => Number(item.row) === Number(row))

        if (findIndex !== -1) {
          this.districtItems[findIndex].district = data.district
        } else {
          this.districtItems.push({
            row,
            district: data.district,
          })
        }

        data.district.forEach(districtItem => {
          this.districtSource.push(districtItem.district_name)
        })

        if (Number(data.total) === 1) instance.jexcel.setValue(columnName, reduceDistrict.district_name)
        this.checkCalculate(instance, row)
      }, err => {
        this.toastErrors(err)
      })
    },
    checkCalculate(instance, row, index) {
      if (this.pasteItem.shipment.item.length > 0) {
        if (this.pasteItem.shipment.item[index].value) {
          let valAddressFrom = columnValue(instance, String(this.columnNumber.address_from), String(this.pasteItem.shipment.item[index].row))
          const valZipcode = columnValue(instance, String(this.columnNumber.zip_code), String(this.pasteItem.shipment.item[index].row))
          const valDistrict = columnValue(instance, String(this.columnNumber.district_name), String(this.pasteItem.shipment.item[index].row))
          const valProduct = columnValue(instance, String(this.columnNumber.product), String(this.pasteItem.shipment.item[index].row))
          const valVariant = columnValue(instance, String(this.columnNumber.variant), String(this.pasteItem.shipment.item[index].row))
          const valQty = columnValue(instance, String(this.columnNumber.qty), String(this.pasteItem.shipment.item[index].row))
          const valPayment = columnValue(instance, String(this.columnNumber.payment_method), String(this.pasteItem.shipment.item[index].row))

          const findProduct = this.variantsProduct.find(items => items.product_name === valProduct)
          const findVariant = findProduct.variant.find(items => items === valVariant)

          if (valAddressFrom && valZipcode && valDistrict && valProduct && findVariant && valQty && valPayment) {
            const weight = this.source.product_weight.find(items => items.product_name === valProduct)

            if (weight) {
              const totalWeight = (Number(valQty) * weight.product_weight) / 1000
              let params
              if (valAddressFrom.includes('Kompack')) {
                const findAddressFrom = this.kompackSource.find(items => valAddressFrom.includes(items.warehouse))
                valAddressFrom = findAddressFrom.warehouse
                params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}&is_kompack=1`
              } else {
                params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}`
              }
              const columnName = column(this.columnNumber.shipment, this.pasteItem.shipment.item[index].row)
              instance.jexcel.setReadOnly(columnName, false)

              if (this.pasteItem.shipment.item[index].value) {
                this.$store.dispatch('ormas/calculate', params).then(response => {
                  const findIndex = this.shipmentItems.findIndex(items => Number(items.row) === Number(this.pasteItem.shipment.item[index].row))

                  if (findIndex === -1) {
                    this.shipmentItems.push({
                      row: this.pasteItem.shipment.item[index].row,
                      shipment: response,
                    })
                  } else {
                    this.shipmentItems[findIndex].shipment = response
                  }

                  configColumn(instance, this.columnNumber.shipment).source = response
                  instance.jexcel.setValue(columnName, this.pasteItem.shipment.item[index].value)
                  this.validationSubmit()
                }, err => {
                  this.toastErrors(err)
                })
              }
            }
          } else {
            const columnName = column(this.columnNumber.shipment, this.pasteItem.shipment.item[index].row)
            instance.jexcel.setValue(columnName, '')
            instance.jexcel.setReadOnly(columnName, true)
            this.validationSubmit()
          }
        } else {
          let valAddressFrom = columnValue(instance, String(this.columnNumber.address_from), row)
          const valZipcode = columnValue(instance, String(this.columnNumber.zip_code), row)
          const valDistrict = columnValue(instance, String(this.columnNumber.district_name), row)
          const valProduct = columnValue(instance, String(this.columnNumber.product), row)
          const valVariant = columnValue(instance, String(this.columnNumber.variant), row)
          const valQty = columnValue(instance, String(this.columnNumber.qty), row)
          const valPayment = columnValue(instance, String(this.columnNumber.payment_method), row)

          if (valAddressFrom && valZipcode && valDistrict && valProduct && valVariant && valQty && valPayment) {
            const weight = this.source.product_weight.find(items => items.product_name === valProduct)

            if (weight) {
              const totalWeight = (valQty * weight.product_weight) / 1000
              let params
              if (valAddressFrom.includes('Kompack')) {
                const findAddressFrom = this.kompackSource.find(items => valAddressFrom.includes(items.warehouse))
                valAddressFrom = findAddressFrom.warehouse
                params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}&is_kompack=1`
              } else {
                params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}`
              }
              this.$store.dispatch('ormas/calculate', params).then(response => {
                const findIndex = this.shipmentItems.findIndex(items => Number(items.row) === Number(row))

                if (findIndex === -1) {
                  this.shipmentItems.push({
                    row,
                    shipment: response,
                  })
                } else {
                  this.shipmentItems[findIndex].shipment = response
                }

                const columnName = column(this.columnNumber.shipment, row)
                instance.jexcel.setReadOnly(columnName, false)
                configColumn(instance, this.columnNumber.shipment).source = response
                const find = this.shipmentLoad.find(items => Number(items.row) === Number(row))
                const findIndexLoad = this.shipmentLoad.findIndex(items => Number(items.row) === Number(row))

                if (find) {
                  instance.jexcel.setValue(columnName, find.shipment)
                  this.shipmentLoad.splice(findIndexLoad, 1)
                }
                this.validationSubmit()
              }, err => {
                this.toastErrors(err)
              })
            }
          } else {
            const columnName = column(this.columnNumber.shipment, row)
            instance.jexcel.setValue(columnName, '')
            instance.jexcel.setReadOnly(columnName, true)
            this.validationSubmit()
          }
        }
      } else {
        let valAddressFrom = columnValue(instance, String(this.columnNumber.address_from), row)
        const valZipcode = columnValue(instance, String(this.columnNumber.zip_code), row)
        const valDistrict = columnValue(instance, String(this.columnNumber.district_name), row)
        const valProduct = columnValue(instance, String(this.columnNumber.product), row)
        const valVariant = columnValue(instance, String(this.columnNumber.variant), row)
        const valQty = columnValue(instance, String(this.columnNumber.qty), row)
        const valPayment = columnValue(instance, String(this.columnNumber.payment_method), row)

        if (valAddressFrom && valZipcode && valDistrict && valProduct && valVariant && valQty && valPayment) {
          const weight = this.source.product_weight.find(items => items.product_name === valProduct)

          if (weight) {
            const totalWeight = (valQty * weight.product_weight) / 1000
            let params
            if (valAddressFrom.includes('Kompack')) {
              const findAddressFrom = this.kompackSource.find(items => valAddressFrom.includes(items.warehouse))
              valAddressFrom = findAddressFrom.warehouse
              params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}&is_kompack=1`
            } else {
              params = `?zip_code=${valZipcode}&address=${valAddressFrom}&weight=${totalWeight}&payment_method=${valPayment}`
            }
            this.$store.dispatch('ormas/calculate', params).then(response => {
              const findIndex = this.shipmentItems.findIndex(items => Number(items.row) === Number(row))

              if (findIndex === -1) {
                this.shipmentItems.push({
                  row,
                  shipment: response,
                })
              } else {
                this.shipmentItems[findIndex].shipment = response
              }

              const columnName = column(this.columnNumber.shipment, row)
              instance.jexcel.setReadOnly(columnName, false)
              configColumn(instance, this.columnNumber.shipment).source = response
              const find = this.shipmentLoad.find(items => Number(items.row) === Number(row))
              const findIndexLoad = this.shipmentLoad.findIndex(items => Number(items.row) === Number(row))

              if (find) {
                instance.jexcel.setValue(columnName, find.shipment)
                this.shipmentLoad.splice(findIndexLoad, 1)
              }

              const colShipment = column(this.columnNumber.shipment, row)
              instance.jexcel.setValue(colShipment, '')
              this.validationSubmit()
            }, err => {
              this.toastErrors(err)
            })
          }
        } else {
          const columnName = column(this.columnNumber.shipment, row)
          instance.jexcel.setValue(columnName, '')
          instance.jexcel.setReadOnly(columnName, true)
          this.validationSubmit()
        }
      }
    },
    validateColumn(cols) {
      let result = false
      if (
        cols !== String(this.columnNumber.custom_label)
        && cols !== String(this.columnNumber.tracking_sales)
        && cols !== String(this.columnNumber.order_date)
        && cols !== String(this.columnNumber.customer_name)
        && cols !== String(this.columnNumber.customer_phone_number)
        && cols !== String(this.columnNumber.customer_address)
        && cols !== String(this.columnNumber.order_notes)
        && cols !== String(this.columnNumber.shipment)
        && cols !== String(this.columnNumber.grandtotal)
      ) {
        result = true
      }
      return result
    },
    errorSubmit(state) {
      if (state?.validation_error?.length) {
        this.errorsState.type = 'validation'
        this.errorsState.data = state.validation_error
      } else if (state?.cod_error?.length) {
        this.errorsState.type = 'cod'
        this.errorsState.data = state.cod_error
      } else if (state.message === 'Your balance is not enough') {
        this.alertState.type = 'saldo'
        return this.showAlert(this.alertState.type)
      } else if (state.message === "There's error in shipping") {
        this.errorsState.type = 'shipment cod'
        this.errorsState.data = state.error_data
      }
      this.$refs['loading-component'].close()
      this.$refs.errors.show()
    },
    async onSave(state) {
      this.loadingSaveDraft = true
      const params = {
        options: 'save',
        data: this.table.getJson(),
      }

      await this.$store.dispatch('ormas/submit', params).then(() => {
        this.$store.dispatch('ormas/lastUpdatedSheets').then(() => {}, err => {
          this.toastErrors(err)
        })
        if (state === 'save-draft') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Sukses Simpan Draft',
              variant: 'success',
            },
          }, 2000)
        }
        this.loadingSaveDraft = false
      })
    },
    onSubmit() {
      this.codError = this.table.getJson()
      const minMaxCod = this.codError.some(item => item.payment_method === 'COD' && item.grandtotal !== '' && (Number(item.grandtotal) < 10000 || Number(item.grandtotal) > 5000000))
      if (minMaxCod) {
        this.$bvModal.show('modal-cod-error')
      } else if (!this.limitOrder.data.data.is_lock) {
        this.$refs['submit-confirm'].show()
      } else {
        this.stateLimitOrder = ''
        this.$bvModal.show('modal-validation-add-order')
      }
    },
    async submit() {
      this.submitIsError.value = false
      this.submitIsError.data = null
      this.onSave('submit')
      let count = 0
      let total = 0
      this.successCount = 0
      this.$refs['submit-confirm'].close()
      this.$refs['loading-component'].handleRunLoading()
      const sheets = this.table.getJson()
      const dataSubmit = []

      const filter = sheets.filter(
        item => item.order_date
          || item.address_from
          || item.customer_name
          || item.customer_phone_number
          || item.zip_code
          || item.district_name
          || item.customer_address
          || item.product
          || item.variant
          || item.qty
          || item.payment_method
          || item.expedition
          || item.grandtotal !== '',
      )

      const mapping = filter.map((item, index) => ({
        is_kompack: this.isKompack(item.address_from),
        order_date: item.order_date !== '' ? moment(item.order_date).format('YYYY-MM-DD') : '',
        custom_label_name: item.custom_label,
        tracking_sales_name: item.tracking_sales,
        address: this.addressSubmit(item.address_from),
        customer_name: item.customer_name,
        customer_phone_number: item.customer_phone_number,
        zip_code: Number(item.zip_code),
        district_name: item.district_name,
        customer_address: item.customer_address,
        product: item.product,
        variant: item.variant,
        order_notes: item.order_notes,
        qty: Number(item.qty),
        payment_method: item.payment_method,
        expedition: item.shipment,
        grandtotal: Number(item.grandtotal.replace(/\D/g, '').replace(onlyNumberRegex, '')),
        row: index += 1,
      }))

      let splitOrder = []
      const dataSplit = []
      for (let index = 0; index < mapping.length; index += 1) {
        if (mapping[index].order_date !== '') {
          if (splitOrder.length > 0) {
            dataSplit.push(splitOrder)
          }
          splitOrder = []
          if (index === mapping.length - 1) {
            splitOrder.push(mapping[index])
            dataSplit.push(splitOrder)
          } else {
            splitOrder.push(mapping[index])
          }
        } else if (index === mapping.length - 1) {
          splitOrder.push(mapping[index])
          dataSplit.push(splitOrder)
        } else {
          splitOrder.push(mapping[index])
        }
      }

      for (let x = 0; x < dataSplit.length; x += 1) {
        if (dataSplit[x].length > 0) {
          dataSplit[x].forEach((item, index) => {
            if (item.address === '') item.address = dataSplit[x][0].address
          })
        }
        const params = {
          options: 'submit',
          data: dataSplit[x],
        }

        await this.$store.dispatch('ormas/submit', params).then(response => {
          if (response.data.data.is_lock) {
            this.statelLimitOrder = 'progress'
            this.$bvModal.show('modal-validation-add-order')
            this.$refs['loading-component'].close()
            return false
          } else {
            count = response.data.data
            total += count
            this.successCount = total
            this.progressValue = Math.floor((total * 100) / dataSplit.length)

            if (this.successCount === dataSplit.length) {
              this.$refs['loading-component'].close()
              this.$refs.success.show()
            }
          }
        }, err => {
          this.submitIsError.data = err
          this.submitIsError.value = true
        })
        if (x + 1 === dataSplit.length) {
          if (this.submitIsError.value) {
            if (this.submitIsError.data.status === 500) {
              this.$refs['loading-component'].close()
              this.toastErrors(this.submitIsError.data.data.message)
            } else {
              this.errorSubmit(this.submitIsError.data.data)
            }
          }
        }
      }
    },
    resetSheets() {
      this.$refs.reset.show()
    },
    confirmResetSheets() {
      this.table.setData([])
      this.$refs.reset.hide()
    },
    validationSubmit() {
      const sheets = this.table.getJson()
      const mapping = sheets.map((item, index) => ({
        order_date: moment(item.order_date).format('YYYY-MM-DD'),
        custom_label: item.custom_label,
        tracking_sales: item.tracking_sales,
        address: item.address_from,
        customer_name: item.customer_name,
        customer_phone_number: item.customer_phone_number,
        zip_code: Number(item.zip_code),
        district_name: item.district_name,
        customer_address: item.customer_address,
        product: item.product,
        variant: item.variant,
        order_notes: item.order_notes,
        qty: Number(item.qty),
        payment_method: item.payment_method,
        expedition: item.shipment,
        grandtotal: Number(item.grandtotal.replace(/\D/g, '').replace(onlyNumberRegex, '')),
        row: index += 1,
      }))

      let countDisable = 0

      const filter = mapping.filter(
        item => item.order_date
          && item.address
          && item.customer_name
          && item.customer_phone_number
          && item.zip_code
          && item.district_name
          && item.customer_address
          && item.product
          && item.variant
          && item.qty
          && item.payment_method
          && item.expedition
          && item.grandtotal,
      )

      if (this.profile.partner_is_custom_label) {
        const find = filter.filter(item => item.custom_label === '')
        if (find.length > 0) {
          countDisable += 1
        }
      }

      if (this.profile.partner_is_tracking_sales) {
        const find = filter.filter(item => item.tracking_sales_name === '')
        if (find.length > 0) {
          countDisable += 1
        }
      }

      if (filter.length === 0 || countDisable > 0) {
        this.disableSubmit = true
      } else {
        this.disableSubmit = false
      }
    },
    addressSubmit(data) {
      let result = ''
      if (data) {
        const warehouse = this.kompackSource.find(item => data.includes(item.warehouse))
        if (warehouse) {
          result = warehouse.warehouse
        } else {
          result = data
        }
      }
      return result
    },
    isKompack(data) {
      let result
      if (data !== '') {
        result = data.includes('Kompack') ? 1 : 0
      } else {
        result = 0
      }
      return result
    },
  },
}
